/* ChoicePage.css */
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");
/* ChoicePage.css */

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  max-width: 600px;
  font-family: PT Sans, sans-serif;
  margin-left: 30px;
  overflow: visible;
}

h1 {
  font-size: 28px;
  font-weight: bold;
  color: #005eb8;
  margin-bottom: 10px;
  white-space: normal;
}

p {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
  white-space: normal;
}

.options-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
  overflow: visible;
}

.option {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
  white-space: normal;
}

.option input[type="radio"],
.option input[type="checkbox"] {
  margin-right: 10px;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  width: 100%;
}

button {
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-family: PT Sans, sans-serif;
}

button:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

#backButton {
  background-color: #005eb8;
  color: #fff;
}

#nextButton {
  background-color: #28a745;
  color: #fff;
}

button:hover:not(:disabled) {
  opacity: 0.9;
}

.start-button {
  padding: 10px 20px;
  background-color: #28a745;
  border: none;
  color: #fff;
  margin: 10px;
  margin-left: -90px;
  align-self: flex-start;
  border-radius: 4px;
  font-family: "PT Sans", sans-serif;
  font-weight: bold;
}

.container-error-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  max-width: 600px;
  font-family: "PT Sans", sans-serif;
  margin-left: 30px;
  overflow: visible;
}

.container-error-page p {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
  white-space: pre-line;
}
