@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

.summary-container {
  margin: 20px;
  font-family: "PT Sans", sans-serif;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.summary-item {
  margin-bottom: 20px;
}

.question {
  font-size: 18px;
}

.options-heading {
  font-weight: bold;
  margin-top: 10px;
}

.options-list {
  list-style-type: none;
  padding-left: 0;
}

.options-list li {
  margin-left: 15px;
  line-height: 1.2;
}

.response {
  margin-top: 10px;
  font-size: 16px;
}

.response strong {
  font-weight: bold;
}

.response em {
  font-weight: normal;
  font-style: normal;
}
