.help-tooltip-container {
  position: relative;
  display: inline-block;
  margin-left: 8px;
}

.help-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  font-size: 10px;
  cursor: pointer;
  font-weight: bold;
  z-index: 2;
}

.tooltip-content {
  visibility: hidden;
  opacity: 0;
  width: 650px;
  max-width: 90vw;
  background-color: #e0f7ff;
  color: #333;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  transform: translateY(8px);
  transition: visibility 0.3s, opacity 0.3s;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.help-tooltip-container:hover .tooltip-content,
.tooltip-content:hover {
  visibility: visible;
  opacity: 1;
}

.tooltip-section {
  margin-bottom: 8px;
}

.tooltip-section strong {
  display: block;
  margin-bottom: 4px;
  color: #007bff;
  font-size: 14px;
}

.tooltip-section p,
.tooltip-section ul {
  margin: 0;
  font-size: 14px;
  color: #555;
  line-height: 1.4;
}

.tooltip-section ul {
  padding-left: 20px;
  list-style-type: disc;
}

.tooltip-section ul li {
  margin-bottom: 4px;
  font-size: 14px;
}
