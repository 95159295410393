@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

body {
  font-family: "PT Sans", sans-serif;
  background-color: #f9f9f9;
  margin: 50px;
  padding: 0;
  color: #333;
}

h1,
h2 {
  color: #0056b3;
}

button {
  cursor: pointer;
}

.landing-page {
  text-align: center;
  align-items: center;
}
.landing-page h3 {
  text-align: center;
  align-items: center;
}

.intro-text {
  text-align: left;
  width: auto;
  margin: 25px;
}

.landing-content-sharecode {
  padding: 60px;
  width: 800px;
  align-self: center;
  justify-self: center;
}

.dataset-page,
.topic-page,
.question-page,
.ethnicity-page,
.location-page,
.loading-page,
.result-page,
.error-page {
  text-align: left;
  padding: 20px;
  margin: 5px;
}

.start-button,
.next-button,
.back-button,
.toggle-button {
  padding: 10px 20px;
  background-color: #28a745;
  border: none;
  color: #fff;
  margin: 10px;
  border-radius: 4px;
  font-family: "PT Sans", sans-serif;
  font-weight: bold;
}

.back-button {
  background-color: #007bff;
  font-family: "PT Sans", sans-serif;
  font-weight: bold;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-family: "PT Sans", sans-serif;
}

.loading-page .loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-content {
  margin-top: 50px;
}

.summary-section {
  text-align: left;
  margin-top: 30px;
}

li {
  padding: 5px;
}

.dataset-list,
.topic-list,
.question-list,
.ethnicity-list,
.loction-list {
  align-items: center;
  align-self: center;
  justify-content: center;
  text-align: left;
  width: 50%;
}

ul {
  list-style-type: none;
}

.link-button {
  font-family: "PT Sans", sans-serif;
  background: none;
  border: none;
  color: #0056b3;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
  margin: 0 10px;
}

.link-button:hover {
  color: #0056b3;
  text-decoration: underline;
}

.create-persona-container {
  display: flex;
  justify-content: center;
  width: 100%;
  font-weight: bold;
}

.create-persona-button {
  background-color: #28a745;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  font-family: "PT Sans", sans-serif;
  font-weight: bold;
}

.date-picker {
  display: flex;
  gap: 5px;
}

.day-picker,
.month-picker {
  width: 35px;
}
.year-picker {
  width: 70px;
}

#backButton {
  background-color: #005eb8;
  color: #fff;
}

#nextButton {
  background-color: #28a745;
  color: #fff;
}

button:hover:not(:disabled) {
  opacity: 0.9;
}

button:disabled {
  opacity: 0.6;
}
