.text-display {
  padding: 0;
  margin-bottom: 0;
  border-radius: 5px;
}

.text-display-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: -29px;
}

.whyarewe {
  font-size: 15px;
}

.dropdown-icon {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 10px solid #333;
  margin-right: 8px;
  transition: transform 0.3s ease;
}

.dropdown-icon.expanded {
  transform: rotate(90deg);
}

.text-display-content {
  margin-top: 0;
  padding-left: 16px;
}

.text-display-section {
  margin-bottom: 0;
}

.section-title {
  font-weight: bold;
  margin-bottom: 0;
}

.section-content {
  color: #555;
  line-height: 1.2;
  margin: 0;
}

.section-content p {
  color: #555;
  margin: 10px;
  font-size: 14px;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  white-space: normal;
  width: 650px;
}
.section-content ul {
  margin: -5px;
  font-size: 14px;
  color: #555;
  line-height: 1.4;
}

.section-content ul {
  padding-left: 20px;
  list-style-type: disc;
}

.section-content ul li {
  margin-bottom: 4px;
  font-size: 14px;
}
